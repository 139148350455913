const buttons = {
  'Recovery button': 'Восстановить',
  'Change button': 'Изменить',
  'Back button': 'Назад',
  'Create button': 'Создать',
  'Save button': 'Сохранить',
  'Update button': 'Обновить',
  'Delete button': 'Удалить',
  'Search button': 'Найти',
  'Clear button': 'Очистить',
  'Copy button': 'Скопировать',
  'Cancel button': 'Отмена',
  'Apply button': 'Подтвердить',
  'Ok button': 'Ok',
  'Back to Home': 'Вернуться на главную',
  'status-city': 'Инфо',
  'cancel-city': 'Отмена',
  'register-city': 'Зарегистрировать',
  cancel_overdraft: 'Отменить овердрафт',
  receipt: 'Квитанция',
  Reference: 'Справка',
  clear_filters: 'Сбросить фильтры'
};

const menu = {
  'Transaction menu': 'Транзакции',
  FinMon: 'Решения ФМ',
  FinMonUpdate: 'Обновление Фин Мониторинг',
  'Users menu': 'Пользователи',
  'Users inactive menu': 'Не активные',
  'Users active menu': 'Активные',
  'Role menu': 'Роли',
  'User Item': 'Информация о пользователе',
  'Banks menu': 'Банки',
  'Flow menu': 'Схемы',
  'Gateway menu': 'Шлюз',
  'Cascading menu': 'Каскад',
  'Cascading rules menu': 'Правила',
  'Cascading models menu': 'Модели',
  'Terminals menu': 'Терминалы',
  'Merchant menu': 'Мерчанты',
  'Description menu': 'Экспорт документов',
  Reports: 'Отчеты',
  'Description menu custom': 'Экспорт',
  'Description menu city24': 'Экспорт city24',
  'Codes menu': 'Коды',
  'Bin menu': 'Bin',
  'Reconciliation menu': 'Сверка',
  'Select Date': 'Выбор даты'
};

const text = {
  userId: 'ID пользователя',
  Download_list_of_terrorists: 'Загрузить список террористов',
  'Having an account': 'Уже есть аккаунт',
  'Reset account password': 'Восстановить доступ',
  'Success update': 'Запись обновлена',
  'Do you want to remove': 'Хотите удалить запись?',
  'Register account': 'Регистрация',
  'Success registration': 'Вы успешно зарегистрированы',
  'Success deleted': 'Запись удалена',
  'Reset title': 'Восстановление пароля',
  'Success recovery send token': 'Инструкция по была отправлена на email',
  'Active user':
    'Вы были неактивны более 15 мин. Сеанс работы был автоматическFи завершен. Авторизируйтесь повторно для продолжения работы',

  'Transactions List': 'Список транзакций',
  'Transactions Item': 'Информация о транзакции',
  'Update Contract': 'Обновить договор',
  'Create Contract': 'Создать договор',
  edrpou: 'ОКПО',
  letter_pumb: 'Письмо ПУМБ',
  letter_c2a: 'Письмо C2A',
  startActionDate: 'Установление деловых отношений',
  birth: 'Дата рождения',
  citizenship: 'Гражданство',
  residency: 'Резиденство',
  shareInCapital: 'Доля в капитале',
  fio: 'ФИО',
  isActive: 'Статус',
  'is current': 'Актуальный',
  'Form Co-Owners': 'Создание соучередителя',
  'Form Co-Owners Edit': 'Редактирование соучередителя',
  'Company Co-Owners': 'Соучередители',
  'Company Co-Owners Persons': 'Соучередители физ. лица',
  'Company Co-Owners Company': 'Соучередители юр. лица',
  nbu_c2a_report: 'Отчет C2A для НБУ',

  'Users List': 'Список пользователей',
  'Add a rule': 'Добавить правило',
  'User Item Update': 'Обновление пользователя',
  'User Item Create': 'Создание пользователя',
  'Act From': 'Начало действия',
  'Act Till': 'Конец действия',

  'Roles List': 'Список ролей',
  'Role Item Create': 'Создание роли',
  'Role Item Update': 'Обновление роли',
  'Company Owners': 'Учредители',

  'Terminals List': 'Список терминалов',
  'Terminals Item Id': 'Информация о терминале',
  'Terminals Model Create': 'Создание нового терминала',
  'Terminals Token Update': 'Обновление ключей',
  'Terminals Item Update': 'Обновление терминала',
  'Terminal Item Id': 'Информация о терминале',
  'Do you want to update token': 'Вы действительно хотите обновить ключ?',

  given: 'Выдача',
  owned: 'Свои средства',
  collected: 'Принятые платежи',
  overdraft: 'Овердрафт',
  available_balance: 'Доступный баланс',

  creditBalance: 'Доступний баланс',
  debitBalance: 'Свои средства',
  given_amount: 'Выдача',
  collected_amount: 'Принятые платежи',

  'Merchant List': 'Список мерчантов',
  'Merchant Item Id': 'Описание мерчанта',
  'Merchant Item Update': 'Обновление мерчанта',
  'Merchant Item Create': 'Создание мерчанта',
  'Merchant Token Update': 'Обновление ключа мерчанта',
  'Merchant Commissions': 'Комиссии',
  'Merchant Commissions External': 'Внешняя комиссия',
  'Merchant Commissions Internal': 'Внутренния комісія',
  'Merchant Notifications': 'Нотификации',
  'Merchant Notifications Email': 'Почта',
  'Merchant Notifications Create': 'Создать',
  'Merchant Percentage': 'Процент',
  'Merchant Name': 'Имя мерчанта',
  'Merchant Settings': 'Настройки мерчанта',
  Company: 'Компания',
  'Block the user': 'Заблокировать пользователя ?',
  reportType: 'Выберите отчет',

  today: 'За сегодня',
  yesterday: 'За вчера',
  previousMonth: 'За предыдущий месяц',
  currentMonth: 'За текущий месяц',
  autoLogout: 'Автовыход через: ',
  documents: 'Договора',

  BalanceLoopBack: 'Закольцовка',
  'Create Commissions': 'Создание комиссии',
  'Update Commissions': 'Редактирование комиссии',
  'FinMon Status': 'Статус ФМ',
  returnTran: 'Возврат',
  idOperations: 'ID операции',
  'Return to the balance': 'Вернуть на баланс',
  'Without changing the balance': 'Без изменения баланса',
  'Register Return': 'Зарегистрировать возврат',
  'In processing': 'В процессе',
  'Processing allowed': 'Обработка разрешена',
  'Ban on operations': 'Запрет на операции',

  'Contract Number': 'Номер контракта',
  'Contract Name': 'Название контракта',
  'Contract Date': 'Дата контракта',

  'Banks List': 'Список банков',
  'Banks Item Id': 'Информация о банке',
  'Bank Item Create': 'Добавление нового банка',
  'Bank Item Update': 'Обновление банка',
  'Bank Deposit Update': 'Обновление лимита',
  'Roles is required field': 'Роли являются обязательным полем',

  'Transactions Flow List': 'Список схем транзакций',
  'Transactions Flow Create': 'Создание схемы транзакции',

  'Gateway List': 'Список шлюзов',
  'Gateway Item Create': 'Создание шлюза',
  'Gateway Item': 'Описание шлюза',
  'Gateway Item Update': 'Обновление шлюза',

  'Cascading Rules List': 'Список правил',
  'Cascading Models List': 'Список моделей',
  'Cascading Model Create': 'Создание модели',
  Confirmation: 'Вы уверены, что хотите продолжить это действие?',
  'Codes List': 'Список кодов',
  'Codes Item Id': 'Информация о коде',
  'Code Item Update': 'Обновления кода',
  'Code Item Create': 'Создание кода',
  'Transactions Logs Item': 'Логи транзакций',
  'Reconciliation List': 'Сверка',
  miss_in_bnk: 'Отсутствует в банке',
  miss_in_pt: 'Отсутствует в paytech',
  trn_not_registered: 'Не зарегистрировано',
  miss_in_bnk2: 'Внимание!!!',
  dateStart: 'Период с',
  dateEnd: 'Период по',
  tranTypeId: 'Тип операции',
  bankId: 'Банк',
  respCode: 'Статус',
  amountFrom: 'Сумма от',
  amountTo: 'Сумма до',
  returnBank: 'Возврат Банк',
  'Manual entry': 'Выбрать вручную',
  'card first 6 number': 'Первые 6 цифр карты',
  'card last 4 number': 'Последние 4 цифры карты',
  tranId: 'ID транзакции',
  gateway: 'Банк',
  acsStatus: 'Статус 3DS',
  amount: 'Сумма',
  approval: 'Код авторизации',
  callbackUrl: 'Call back URL',
  fee: 'Комиссия',
  getewayRefNo: 'ID банка',
  lang: 'Язык',
  pan: 'Карта',
  respCodeId: 'Код ответа',
  rrn: 'RRN',
  tranType: 'Тип операции',
  description: 'Торговая марка',
  status: 'Статус',
  timezone: 'Временная зона',
  comment: 'Коментарии',
  bankName: 'Банк',
  fields: 'Другие поля',
  langEn: 'Английский',
  langRu: 'Русский',
  langUk: 'Украинский',
  external: 'Код',
  merchId: 'Мерчант',
  Profile: 'Профиль',
  Settings: 'Настройки',
  Logout: 'Выйти',
  Balance: 'Баланс',
  Balanc: 'Балансы',
  'For merch': 'Для мерчанта',
  'Own Founds': 'Собственные средства',
  'Accepted Payments': 'Принятые платежи',
  'Own founds at the begiining of the day':
    'Собственные средства на начало дня',

  'Export List': 'Экспорт файлов',
  generated_reports_list: 'Список доступных файлов',
  'City24 Files List': 'Экспорт файлов city24',
  'Download File': 'Скачать файл',
  'Bin List': 'Bin',
  'Invitation link': 'Ссылка для приглашения пользователей',
  'Register payments': 'Реестровые платежи',
  'Check registry': 'Проверить реестр',
  decreaseAmount: 'Сумма уменьшения',
  'Overdraft Date': 'Снять в',
  'Date range': 'Диапазон дат не может превышать 1 год',

  'Success tab': 'Успешные',
  'Failed tab': 'Отклоненные',
  'Conversion tab': 'Конверсия',
  readiness: 'Готовность',
  'New Password': 'Новый пароль',
  'Confirm New Password': 'Подтвердить новый пароль',
  'Change Password': 'Изменить пароль',

  'Password was successfully changed': 'Пароль был успешно изменен',
  'Reset success send':
    'Инструкция по восстановлению пароля была отправлена на указанный email',
  'Request success send': 'Запрос успешно отправлен',
  'Current month': 'Текущий месяц',
  'Last month': 'Прошлый месяц',
  'Last 3 month': 'Прошедших 3 месяца',
  'Last 6 month': 'Прошедших 6 месяцев',
  'Beginning of the year': 'С начала года',
  'Get by': 'Получить за',
  'Available balance': 'Доступный баланс',
  'Own funds': 'Свои средства',
  Overdraft: 'Овердрафт',
  'Accepted payments': 'Принятые платежи',
  Payments: 'Выдача',
  'using own funds': 'c собственных средств',
  'using overdraft': 'c овердрафта',
  'using accepted payments': 'c С2A',
  'used overdraft': 'использованный овердрафт',
  terrorists_list: 'Список Террористов',
  sanctions_list: 'Санкционный Список',
  STATUS_NEW: 'NEW',
  STATUS_PROCESSING: 'PROCESSING',
  STATUS_FINISHED: 'FINISHED',
  STATUS_FAILED: 'FAILED',
  userName: 'Пользователь',
  fileName: 'Имя Файла',
  gatewayRefNo: 'Bank-ID',
  'Last Update': 'Последнее Обновление',
  Update: 'Обновить',
  companyEmail: 'Электронная почта компании',
  accountNumber: 'Номер аккаунта',
  bankMfo: 'MFO',
  mainPhone: 'Основной номер',
  city: 'Город',
  address: 'Адрес',
  'Companies List': 'Список Компаний',
  'Companies Create': 'Создание компании',
  'Companies Success': 'Компания успешно создана',
  'Companies Fail': 'Ошибка',
  'Extra Data': 'Дополнительная информация',
  terStatus: 'Статус проверки по списку террористов',
  terCheck: 'Дата/Время проверки по списку террористов',
  sanStatus: 'Статус проверки по санкциям РНБО',
  sanCheck: 'Дата/Время проверки по санкциям РНБО',
  'Two-factor authentication': 'Двухфакторная аутентификация',
  enabled: 'Включено',
  disabled: 'Выключено',
  disableClick: 'Выключить',
  enableClick: 'Включить',
  code: 'ИНН'
};

const fields = {
  orderBy: 'Сортировать по',
  sortDescending: 'По убыванию',
  sortAscending: 'По возрастанию',
  uuid: 'ID',
  name: 'Название',
  resolution: 'Решение',
  createOn: 'Создано',
  merchant: 'Мерчант',
  tranId: 'Id транзакции',
  tranType: 'Тип операции',
  pan: 'Номер карты',
  amount: 'Сумма',
  fee: 'Комиссия',
  gateway: 'Экваир',
  respCode: 'Код ответа',
  lang: 'Язык',
  editOn: 'Обновлено',
  firstName: 'Имя',
  lastName: 'Фамилия',
  birthDate: 'Дата рождения',
  extId: 'Внешний ID',
  placeOfBirth: 'Место рождения',
  sanctions_date: 'Дата санкций',
  fullName: 'Полное наименование',
  middleName: 'Отчество',
  inn: 'ИНН',
  phone: 'Телефон',
  loginTries: 'Попыток авторизации',
  lastLogin: 'Дата последней авторизации',
  linkToken: 'Ключ',
  role: 'Роль',
  'email table': 'Email',
  field_email: 'Email',
  'role name field': 'Роль',
  'permissions field': 'Разрешения',
  'name bank field': 'Название банка',
  depositLimit: 'Лимит депозита',
  commissions: 'Комиссии',
  keyToken: 'Ключи',
  flowName: 'Название схемы',
  endpoint: 'Точка входа',
  env: 'Окружение',
  bank: 'Банк',
  'name gateway field': 'Название шлюза',
  'Select value': 'Выбрать значение',
  type_operation: 'Тип операции',
  notificationChannel: 'Канал нотификации',
  'Set deposit limit': 'Установить',
  'Increase deposit limit': 'Увеличить',
  'Decrease deposit limit': 'Уменьшить',
  'Comment show': 'Комментарий',
  Loading: 'Загрузка',
  'Upload file': 'Загрузить файл',
  'Download file': 'Скачать файл',
  'File processing': 'Обработка файла',
  'Invalid cards detected': 'Выявлены недействительные карты',
  checkStatusOk: 'Спасибо за ожидание. Все карты из файла валидные',
  cityRespCode: 'City24 статус',
  company_email: 'Email компании',
  company_id: 'ID компании',
  transaction_count: 'Колличество транзакций',
  transaction_amount: 'Сумма всех операций',
  transaction_fee: 'Сумма комиссии',
  businessName: 'Название компании',
  contractNumber: 'Номер договора',
  contractDate: 'Дата договора',
  gateRespCode: 'Код ответа гейта',
  finMonCheck: 'Статус ФМ',
  inputType: 'Тип платежа',
  frictionless: '3ds Сценарий',
  sanListCheckStatus: 'Статус проверки по санкциям РНБО',
  sanListCheckDate: 'Дата/Время проверки по санкциям РНБО',
  terListCheckStatus: 'Статус проверки по списку террористов',
  terListCheckDate: 'Дата/время проверки по перечню террористов',
  contractName: 'Наименование договора',

  agreementDate: 'Дата соглашения',
  clientName: 'Имя клиента',
  respCodeColor: 'Маркировка',
  respMessage: 'Текст ответа',
  gatewayRefNo: 'ID операции гейта',
  receiver: 'Получатель',
  clear_filters: 'Reset filters',
  account_report: 'Бухгалтерський звіт',
  pci_a2c_nbu: 'Звіт A2C МПС для НБУ',
  balancereportprocessor: 'Звіт по балансам марчантів',
  dailytransactionsstatus: 'Звіт по операціям(денний)',
  dovidka: 'Звіт реєстру довідок',
  nbuform1merchantreportprocessor: 'Форма1 НБУ',
  nbuform2merchantreportprocessor: 'Форма2 НБУ до 01.08.2024',
  nbuform3merchantreportprocessor: 'Форма3 НБУ',
  payment_operation_registry_nbu21processor:
    'Реєстр платіжних операцій (Додаток 2)_перевірка НБУ_2.1.',
  new_default: 'Стандартний звіт(основний)',
  to10nbu: 'Топ-10 ЕПЗ для НБУ',
  merchantdailyreport: 'Стандартний звіт',
  combine_report_no_trx: 'Зведений звіт Б/Т (бухгалтерія)',
  combine_report: 'Зведений звіт, повний (бухгалтерія)',
  decline_codes: 'Коди відмов по операціям',
  finmoncheckresult: 'Звіт Фін-Мон перевірки операцій',
  merchant_balances: 'Баланси мерчанта',
  merchant_fee: 'Комісії мерчанта',
  form2from010824v2reportprocessor: 'Форма2 НБУ з 01.08.2024',
  form5reportprocessor: 'Форма5 НБУ',
  nbuform4reportprocessor: 'Форма4 НБУ',
  over2percard2c: 'Статистика по картам >3 A2C',
  over2percard2a: 'Статистика по картам >2 C2A',
  standartreport: 'Стандартний звіт (обороти)',
  toshortpayaftergiveoutprocessor: 'Передчасне С2A після А2С',
  is3ds: '3D-Secure enabled',
  for_charge: 'Может использоваться для Charge',
  is_apple_pay: 'Исключительно для ApplePay',
  is_google_pay: 'Исключительно GooglePay',
  'Company Signatures': 'Персонал',
  manager: 'ФИО Директора',
  'Company Fio Signatures': 'ФИО',
  'Company Status Signatures': 'Статус',
  'Company Job Signatures': 'Должность',
  'Company Active Signatures': 'Активный',
  'Company NotActive Signatures': 'Не активный',
  'Create Signatures': 'Создание подписанта',
  'Sunction or Terrorist list': 'Совадений не найдено',
  CheckedFinMon: 'Проверено, в дополнительных решениях не требуется',
  FinMonTableUser: 'Пользователь',
  FinMonTableDate: 'Дата/время',
  FinMonTableComment: 'Коментарий',

  'In progress': 'В процессе',
  'Search file': 'Найти файл',
  Verified: 'Проверено',
  'Found in lists': 'Найдено в списках'
};

const server = {
  'Token error': 'Неверный ключ',
  '404 error title': 'Страница не найдена',
  '404 error description':
    'Страница не найдена. Скорее всего она была удалено или перемещена :('
};

const validationForm = {
  required: 'Поле обязательно для заполнения',
  email: 'Поле должно быть почтовым ящиком',
  'Passwords must match': 'Пароли не совпадают',
  'Error max special characters':
    'Ошибка максимального количества специальных символов',
  'Error password format':
    'В пароле должно быть минимум 12 символом. Используйте прописные и заглавные буквы,а также цифры и спец. символы',
  'Error phone format': 'Телефон должен быть в формате 380XXXXXXXXX'
};

export const ru = {
  'Forgot password': 'Забыли пароль?',
  'Create new account': 'Создать аккаунт',
  'Login text': 'Авторизация',
  ...buttons,
  ...fields,
  ...text,
  ...validationForm,
  ...menu,
  ...server
};
